import React from 'react';
import PropTypes from 'prop-types';
import Div100vh from 'react-div-100vh';

import './globalStyles.scss';
import s from './layout.module.scss';

const Layout = ({ children }) => (
  <Div100vh className={s.layout}>
    {children}
  </Div100vh>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
