/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

// import OgImage from '../images/livvin.png';

const getMeta = ({
  title,
  description,
  siteUrl,
  ogImage,
}) => [
  { name: 'description', content: description },
  { name: 'robots', content: 'all' },
  { itemprop: 'name', content: title },
  { itemprop: 'description', content: description },
  { itemprop: 'image', content: `${siteUrl}${ogImage}` },
  { property: 'og:title', content: title },
  { property: 'og:description', content: description },
  { property: 'og:type', content: 'website' },
  { property: 'og:url', content: siteUrl },
  { property: 'og:image', content: `${siteUrl}${ogImage}` },
  { property: 'og:image:width', content: '1200' },
  { property: 'og:image:height', content: '630' },
  { name: 'twitter:card', content: 'summary_large_image' },
  { name: 'twitter:site', content: '@livvin' },
  { name: 'twitter:creator', content: '@livvin' },
  { name: 'twitter:url', content: siteUrl },
  { name: 'twitter:title', content: title },
  { name: 'twitter:description', content: description },
  { name: 'twitter:image:src', content: `${siteUrl}${ogImage}` },
];

const SEO = ({ title }) => {
  const { site } = useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          title
          titleTemplate
          description,
          siteUrl: url
          ogImage: image
        }
      }
    }
  `);

  return (
    <Helmet
      htmlAttributes={{ lang: 'no-nb' }}
      title={title}
      titleTemplate={site.siteMetadata.titleTemplate}
      meta={getMeta({
        title: site.siteMetadata.title,
        description: site.siteMetadata.description,
        siteUrl: site.siteMetadata.siteUrl,
        ogImage: site.siteMetadata.ogImage,
      })}
    />
  );
};

SEO.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SEO;
